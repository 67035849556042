<template>
  <div>
    <h3>the motivators - my new life, what i now have in my possession</h3>
    <ul class="ml-8">
      <li><strong><em>have good boners, bust good nuts</em></strong>, I want her to be completely satisfied</li>
      <li><strong><em>More money than I could imagine</em></strong>. I never have to worry about my next paycheck.</li>
      <li>New Friends, New Environments, New Attitudes, New Me</li>
      <li><strong><em>Map of USA</em></strong>, with pins for travel, family</li>
      <li><strong><em>Map of world</em></strong>, with pins for travel, family</li>
      <li>Maps of cities, that I live in</li>
      <li><strong><em>How would I raise my sons and daughters?</em></strong></li>
    </ul>
  </div>
</template>


<script>
export default {
  name: 'HomeMotivators'
}
</script>
