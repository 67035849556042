<template>
  <div class="home-wrapper d-flex justify-center mb-16">
    <div class="home-container">

      <div class="heading text-center py-16">
        <h1 class="text-decoration-underline">grand rising</h1>
        <h2>grace and peace to you from <br/>god our father and the <br/>big homie gsus christ</h2>
      </div>

      <ol class="ml-8">
        <li><HomeFeelings /></li>
        <li><HomeMotivators /></li>
        <li><HomeSolutions /></li>
      </ol>

    </div>
  </div>
</template>

<style scoped>
.home-container {
  max-width: 400px;
}
ol li {
  margin-bottom: 64px;
  //margin-left: 18px;
  &:last-child {
    margin-bottom: 32px;
  }
}
</style>

<script>
import { defineComponent } from 'vue';
import HomeMotivators from "@/components/HomeMotivators.vue";
import HomeFeelings from "@/components/HomeFeelings.vue";
import HomeSolutions from "@/components/HomeSolutions.vue";
export default defineComponent({
  name: 'HomeView',
  components: {HomeSolutions, HomeFeelings, HomeMotivators},
});
</script>
