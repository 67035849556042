<template>
  <div>
    <h3>common problems & their pre-solved solutions</h3>
    <ul class="ml-8">
      <li></li>
    </ul>
  </div>
</template>


<script>
export default {
  name: 'HomeSolutions'
}
</script>
