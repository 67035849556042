<template>
  <div>
    <h3>the feelings - when i wake up</h3>
    <ul class="ml-8">
      <li>Angry</li>
      <li>Tired / Lazy / Sleepy</li>
      <li>Horny</li>
      <li>Anxious</li>
      <li>Sad / Heartbroken</li>
    </ul>
    <h5 class=" mt-3 text-decoration-underline">HOW I WANT TO WAKE UP</h5>
    <ul class="ml-8">
      <li><strong><em>Hopeful, energetic, eager (not anxious)</em></strong> to start my day and do my things.</li>
      <li><strong><em>Grateful</em></strong> — I want to remember that it could be much, much worse. And be grateful it's not.</li>
      <li>I wanna <strong><em>be at peace</em></strong></li>
    </ul>
  </div>
</template>


<script>
export default {
  name: 'HomeFeelings'
}
</script>
